<template>
  <div>
    <page-header name="Users">
      <template #icon>
        <b-icon
          icon="users"
          icon-pack="fas"
        />
      </template>

      <template #actions>
        <b-button
          tag="router-link"
          to="/users/add"
          type="is-info"
          icon-left="plus"
          icon-pack="fas"
        >
          add new user
        </b-button>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <users-table
          :roles="roles"
          @assign-role="assignRole"
          @edit-user="editUser"
          @send-email="sendEmail"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PageHeader } from '@/components/Shared';
import { UsersTable } from '@/components/Users';

export default {

  name: 'ListUsers',

  components: {
    PageHeader,
    UsersTable,
  },

  computed: {
    ...mapGetters({
      roles: 'Roles/getRoles',
    }),
  },

  async created() {
    try {
      await this.$store.dispatch('Roles/fetchRoles');
      return this.$buefy.notification.open({
        message: 'Successfully retrieved the roles',
        type: 'is-success',
      });
    } catch (error) {
      return this.$buefy.notification.open({
        message: 'Error retrieving the roles',
        type: 'is-warning',
      });
    }
  },

  methods: {
    editUser(userId) {
      this.$router.push(`/users/${userId}/edit`);
    },

    sendEmail(email) {
      window.open(`mailto:${email}`);
    },

    async assignRole(payload) {
      try {
        await this.$store.dispatch('Roles/assignRole', payload);
        await this.$store.dispatch('Users/fetchUsers');
        return this.$buefy.notification.open({
          message: 'Successfully reassigned the role',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
